import React from "react";
import { Link, Paper, Grid, Typography } from "@mui/material";
import Title from "./Title";

const Umsteigen = () => {
    return (
        <Grid item xs={12} md={12} lg={12}>

          <Paper
            sx={{
              p: 2,
              mb: 2,
              display: "flex",
              flexDirection: "column",
              maxWidth: "100%",
            }}
          >
            <Title>#umsteigen</Title>
            <Typography variant="body2" color="inherit">
              Nutzen Sie die P+R Mobilitätsparkplätze in Aalen und Heidenheim, um entspannt mit dem Werkbus in die Werke zu kommen. Alle Informationen und Fahrpläne finden Sie unter <Link href="https://zeiss.ly/umsteigen"
              >zeiss.ly/umsteigen</Link>.
            </Typography>
          </Paper>
        </Grid>
      );
}

export default Umsteigen;