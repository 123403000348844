import * as React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

function Title({ Titel, Auslastung }) {
  let color = "";
  if (Auslastung < 100) {
    color = "red"
  } else if (Auslastung < 200) {
    color = "orange"
  } else if (Auslastung < 10000){
    color = "green"
  } else {
    color = "primary"
  }
  return (
    <Typography component="h2" variant="h6" color={color} gutterBottom>
      {Titel}
    </Typography>
  );
}

Title.propTypes = {
  children: PropTypes.node,
};

export default Title;
