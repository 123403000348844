import * as React from "react";

import Typography from "@mui/material/Typography";
import Title from "./Title";

export default function Deposits({ available, datum, header, Auslastung }) {

  return (
    <React.Fragment>
      <Title Titel={header} Auslastung={Auslastung}/>
      <Typography component="p" variant="h4">
        {available}
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
        {datum}
      </Typography>
    </React.Fragment>
  );
}
