import * as React from "react";
import { useState, useEffect } from "react";
import Title from "./Title";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";

export default function Charts() {
  const [data, setData] = useState(null);
  const [timeData, setTimeData] = useState([]);
  const [amountData, setAmountData] = useState([]);
  const [auslastung, setAuslastung] = useState([]);
  const [auslastung2, setAuslastung2] = useState([]);
  const [average, setAverage] = useState([]);
  const [prediction, setPrediction] = useState(null)

  Chart.register(...registerables);

  const startHour = 6; // Starting hour (inclusive)
  const endHour = 17; // Ending hour (inclusive)

  const fetchPrediction = async () => {
    const response = await fetch("https://parkhausapipy.azurewebsites.net/cast")
    const data = await response.json()
    const jsonData =  JSON.parse(data)
    setPrediction(jsonData)
  }

  useEffect(() => {
    // Fetch data from API and store in state

    fetch("https://smt-parkhaus.azurewebsites.net/api/daily")
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.error(error));

    fetchPrediction();
  }, []);




  useEffect(() => {
    // Calculate the difference between Akt and Max values
    if (data) {
      for (let index = 0; index < data.recordset.length; index++) {
        const time = data.recordset[index].Uhrzeit;
        const amount = data.recordset[index].Akt;

        const timeSplit = time.split(":");
        const minutes = parseInt(timeSplit[1], 10);
        const hours = parseInt(timeSplit[0], 10);

        if (hours >= startHour && hours <= (endHour + 1) ) {
          if (minutes === 0 || minutes === 30 || minutes === 15 || minutes === 45) {
            setTimeData((timeData) => [...timeData, time]);
            if (timeData.includes(time)) {
            } else {
              setAmountData((amountData) => [...amountData, amount]);
            }
          }
        }
      }
      // chartData.push(...chartData, createData(time, amount));
    }
  }, [data]);


  const halfHourValues = [];
  useEffect(() => {
    if (prediction) {
    for (const [key, value] of Object.entries(prediction["0"])) {
      const timeObj = new Date(`2022-01-01T${key}:00`);
      const sHour = new Date(`2022-01-01T0${startHour}:00:00`); // replace with starting hour
      const eHour = new Date(`2022-01-01T${endHour + 1}:00:00`); // replace with ending hour
      if (timeObj >= sHour && timeObj <= eHour &&  (timeObj.getMinutes() === 0 || timeObj.getMinutes() === 30 || timeObj.getMinutes() === 15 || timeObj.getMinutes() === 45)) {
        halfHourValues.push(value);
      }
    }
    for (let index = 0; index < 48; index++) {
      if (index >= 25) {
        setAuslastung2((x) => [...x, 2300]);
         setAuslastung((x) => [...x, null]);
       } else {
         setAuslastung((x) => [...x, 2070]);
         setAuslastung2((x) => [...x, null]);
     }
    }
    
  }
  setAverage(halfHourValues)
  }, [prediction]);


  const labels = [];

  for (let hour = startHour; hour <= endHour; hour++) {
    labels.push(`${hour}:00`);
    labels.push(`${hour}:15`);
    labels.push(`${hour}:30`);
    labels.push(`${hour}:45`);
  }

  const chartData = {
    labels,
    datasets: [
      {
        label: "Verwendete Parkplätze",
        lineTension: 0.3,
        data: amountData,
        borderColor: "rgb(255, 99, 132)",
        borderWidth: 3,
        backgroundColor: "rgba(255, 99, 132, 1)",
        pointRadius: 2,
      },
      {
        data: auslastung,
        label: "Maximale Auslastung",
        borderColor: "rgba(0, 0, 0, 0.6)",
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        pointRadius: 0.2,
      },
      {
        data: auslastung2,
        borderColor: "rgba(0, 0, 0, 0.6)",
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        pointRadius: 0.2,
      },
      {
        data: average,
        label: "Vorraussichtliche Auslastung",
        lineTension: 0.3,
        borderColor: "rgba(90, 100, 200, 0.6)",
        backgroundColor: "rgba(90, 100, 200, 1)",
        borderWidth: 3,
        borderDash: [3, 4],
        pointRadius: 0.9,
      },
    ],
  };

  const options = {
    responsive: true,
    bezierCurve: true,
    maintainAspectRatio: true,
    plugins: {
      title: {
        display: false,
        text: "Chart Title",
        responsive: true,
        font: {
          size: 16,
        },
      },
      legend: {
        display: true,
        position: "top",
        align: "center",
        labels: {
          filter: function (legendItem, chartData) {
            return legendItem.datasetIndex !== 2 && legendItem.datasetIndex !== 1; // Show only the legend for the first dataset
          },
          boxWidth: 20,
          fontSize: 12,
        },
      },
    },
    scales: {
      y: {
        max: 2600,
        min: 0,
      },
      x: {
        ticks: {
          display: true,
        },
      },
    },
  };
  if (timeData) {
    return (
      <React.Fragment>
        <Title>Südwerk-Parkhausauslastung</Title>
        <a>Auf Legende drücken um Linien auszublenden</a>
        <Line options={options} data={chartData} />
      </React.Fragment>
    );
  } else {
    return <p>Loading...</p>;
  }
}
