import * as React from "react";
import { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Charts from "./Chart";
import Deposits from "./Deposits";
import Forcast from "./Forecast";
import Umsteigen from "./umsteigen";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import CircularProgress from '@mui/material/CircularProgress';
import Footer from "./Footer";
// import HolidayAlert from "./HolidayAlert";
import Faq from "./FAQ";


var reactPlugin = new ReactPlugin();

const mdTheme = createTheme();

function DashboardContent() {
  const [data, setData] = useState(null);
  const [prediction, setPrediction] = useState(null)
  const [difference, setDifference] = useState(null);
  const [einfahrt, setEinfahrt] = useState(null);

  const fetchData = async () => {
    try {
      const response = await fetch("https://smt-parkhaus.azurewebsites.net/api/daily");
      const data = await response.json();
      setData(data);
    } catch (error) {
      console.error(error);
    }
  }

  const fetchPrediction = async () => {
    const response = await fetch("https://parkhausapipy.azurewebsites.net/time")
    const data = await response.json()
    console.log(data)
    if (data.time !== null) {
      setPrediction(data.time)
    }
  }
  useEffect(() => {

    // Fetch your data immediately after the component is mounted
    fetchData();
    fetchPrediction();

    // // Then set an interval to fetch it again every 5 minutes
    // const interval = setInterval(() => {
    //   fetchData();
    // }, 1 * 60 * 1000); // 5 minutes in milliseconds

    // // Clear the interval when the component is unmounted
    // return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // Calculate the difference between Akt and Max values
    if (data) {
      const rowsAffected = parseInt(data.rowsAffected[0]) - 1;

      const akt = parseInt(data.recordset[rowsAffected].Akt);
      const max = parseInt(data.recordset[rowsAffected].Max);
      const ein = parseInt(data.recordset[rowsAffected].Ein);

      const time = data.recordset[rowsAffected].Uhrzeit;

      const timeSplit = time.split(":");
      const hours = parseInt(timeSplit[0], 10);

      setEinfahrt(ein);
      if (hours >= 12) {
        setDifference(Math.max(0, max - akt - 66));
      } else {
        setDifference(Math.max(0, 2070 - akt ));
      }
    }
  }, [data]);

  // useEffect(() => {
  //   // Refresh data every 3 minutes
  //   const intervalId = setInterval(() => {
  //     fetch("https://smt-parkhaus.azurewebsites.net/api/daily")
  //       .then((response) => response.json())
  //       .then((data) => setData(data))
  //       .catch((error) => console.error(error));
  //   }, 3 * 60 * 1000);

  //   console.log("fetched");

  //   return () => clearInterval(intervalId);
  // }, []);


  let startTime = null
  let endTime = null

  if (prediction !== null) {
    let receivedTime = prediction
    let timeParts = receivedTime.split(":");
    let timeObject = new Date();
    timeObject.setHours(timeParts[0]);
    timeObject.setMinutes(timeParts[1]);

    let startTimeObject = new Date(timeObject.getTime() - 10 * 60000);
    let endTimeObject = new Date(timeObject.getTime() + 5 * 60000);

    startTime = startTimeObject.getHours().toString().padStart(2, "0") + ":" + startTimeObject.getMinutes().toString().padStart(2, "0");
    endTime = endTimeObject.getHours().toString().padStart(2, "0") + ":" + endTimeObject.getMinutes().toString().padStart(2, "0");
    // console.log(data);
  }

  if (data != null) {
    return (
      <ThemeProvider theme={mdTheme}>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
              <Grid container spacing={3}>
                {/* Chart */}
                <Grid item xs={12} md={8} lg={9}>
                  <Paper
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    {/* <HolidayAlert /> */}
                    <Charts />
                  </Paper>
                </Grid>
                {/* Recent Deposits */}
                <Grid item xs={12} md={4} lg={3}>
                  <Paper
                    sx={{
                      p: 2,
                      mb: 2,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Deposits
                      available={difference}
                      datum={
                        "Stand: " +
                        data.recordset[data.rowsAffected[0] - 1].Uhrzeit
                      }
                      header={"Verfügbare Parkplätze"}
                      Auslastung={difference}
                    />
                  </Paper>
                  <Paper
                    sx={{
                      p: 2,
                      mb: 2,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Deposits
                      available={(prediction !== null) ? (`${startTime} - ${endTime}`) : ("-")}
                      header={"Parkhaus voraussichtlich voll zwischen"}
                    />
                  </Paper>
                  <Paper
                    sx={{
                      p: 2,
                      mb: 2,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Deposits
                      available={Math.max(
                        0,
                        288 -
                        parseInt(data.recordset[data.rowsAffected[0] - 1].E10)
                      )}
                      header={"Verfügbare Schichtparkplätze"}
                      datum={"Auf Ebene 10"}
                    />
                  </Paper>
                </Grid>
                <Umsteigen />
                <Grid item xs={12} md={12} lg={12}>
                  <Paper
                    sx={{
                      p: 2,
                      mb: 2,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Faq />
                  </Paper>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <Paper
                    sx={{
                      p: 2,
                      mb: 2,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Forcast />
                  </Paper>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <Paper
                    sx={{
                      p: 2,
                      mb: 2,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Footer />
                  </Paper>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    );
  } else {
    return <p>Loading...</p>;
  }
}

export default function Dashboard() {
  return <DashboardContent />;
}
