import React, { useState } from "react";
import Dashboard from "./Dashboard";
import {
  AppBar,
  Typography,
  Toolbar,
  FormControlLabel,
  Switch,
} from "@mui/material";
import logo from "../zeiss-logo-tagline_rgb.png";
import { alpha, styled } from "@mui/material/styles";
import { pink } from "@mui/material/colors";

// const PinkSwitch = styled(Switch)(({ theme }) => ({
//   "& .MuiSwitch-switchBase.Mui-checked": {
//     color: pink[600],
//     "&:hover": {
//       backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
//     },
//   },
//   "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
//     backgroundColor: pink[600],
//   },
// }));

const Homepage = () => {
  const [isSMT, setIsSMT] = useState(true);

  const toggleHomepage = () => {
    setIsSMT(!isSMT);
  };

  return (
    <div>
      <AppBar position="absolute">
        <Toolbar>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            Parkhaus-Dashboard
          </Typography>
          {/* <FormControlLabel
            sx={{ mr: 3 }}
            control={<PinkSwitch checked={isSMT} onChange={toggleHomepage} />}
            label="SMT"
          />  */}
          <img src={logo} height={70} alt="logo" />
        </Toolbar>
      </AppBar>
      <Dashboard /> 
     {/* {isSMT ? (
        <Dashboard />
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
          }}
        >
          <h1>Stammwerkparkhaus kommt bald </h1>
        </div>
      )}  */}
    </div>
  );
};

export default Homepage;
