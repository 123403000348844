import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Link, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Faq = () => {
    const faqItems = [
        {
            question: 'Wie wird die erwartete Auslastung berechnet?',
            answer: 'Unsere erwartete Auslastung basiert auf einer Berechnung, die auf den Daten der letzten sechs Wochen basiert. Wir verwenden die Parkhausdaten, um ein Rolling Average für jeden Wochentag zu berechnen, indem wir den Durchschnitt der Auslastung für jeden Stundenslot nehmen. Wir berechnen den Rolling Average für Montag, Dienstag, Mittwoch, Donnerstag und Freitag separat. Wir verwenden dann den berechneten Rolling Average, um die erwartete Auslastung für jeden Wochentag und jede Stunde zu schätzen. Bitte beachten Sie, dass die erwartete Auslastung auf historischen Daten basiert und von der tatsächlichen Auslastung abweichen kann, insbesondere während der Ferienzeit oder bei unvorhergesehenen Ereignissen.'
        },
        {
            question: 'Können E-Parkplätze besser überwacht werden?',
            answer: 'Nein, wir haben leider keinen Überblick über die E-Parkplätze. Diese Informationen liegen nur dem externen Dienstleister vor. Die E-Parkplätze sind jedoch nur zum Aufladen der Fahrzeuge gedacht. Bitte nehmen Sie Rücksicht auf Ihre Kollegen.'
        },
        {
            question: 'Ich habe Probleme mit meinem Parkausweis, an wen muss ich mich wenden?',
            answer: `Bei generellen Anfragen rund um das Parkhaus wenden Sie sich bitte an die Kollgen der <a href="https://zeiss.sharepoint.com/sites/EP_Germany/DE/kands/KSL_OKO_Weg-zu-ZEISS-in-Oberkochen/Seiten/lshomepage.aspx">#Mobilität</a>`
        }
    ];

    return (
        <div>
            <h1>Häufig gestellte Fragen</h1>
            {faqItems.map((item, index) => (
                <Accordion key={index}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>{item.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography dangerouslySetInnerHTML={{ __html: item.answer }} />
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
};

export default Faq;